var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ma-0 px-0 py-1", attrs: { flat: "" } }, [
    _c(
      "div",
      { staticClass: "d-flex align-center w-100 shadow-round-container" },
      [
        _c("Icon", {
          staticClass: "secondary--text font-weight-bold",
          attrs: {
            data: require("@icon/search.svg"),
            width: "34",
            height: "34"
          }
        }),
        _c("div", { staticClass: "searchInput" }, [_vm._t("input")], 2),
        _c("div", { staticClass: "clear" }, [_vm._t("clearInputTest")], 2)
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }