var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-0 px-0 py-1 w-100", attrs: { id: "slot", flat: "" } },
    [
      _c(
        "div",
        {
          staticClass: "rounded-l-xl rounded-r-xl d-flex align-center w-100",
          class: _vm.bgClr
        },
        [
          _c("Icon", {
            staticClass: "icon--text font-weight-bold",
            attrs: {
              data: require("@icon/search.svg"),
              width: "25",
              height: "25"
            }
          }),
          _c("div", { staticClass: "searchInput w-100" }, [_vm._t("input")], 2),
          _c("div", { staticClass: "clear" }, [_vm._t("clearInputTest")], 2)
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }