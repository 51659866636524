var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "white" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "align-star" },
            [
              _c("v-col", { staticClass: "black--text flex-grow-0" }, [
                _c(
                  "div",
                  { staticClass: "app-icon" },
                  [
                    _c("v-img", {
                      staticClass: "rounded-xl",
                      attrs: { src: _vm.baseImgUrl + _vm.serviceAppIcon }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "v-col",
                { staticClass: "black--text flex-grow-1" },
                [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.websiteName + _vm.$t("bottomNav.service")) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "grey--text darken-1" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("service.24HoursAtYourService")) + " "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-7",
                      attrs: { height: "30", rounded: "", color: "#0080ff" },
                      on: { click: _vm.downloadServiceApp }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("service.freeInstallation")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "grey--text darken-1" },
            [
              _c("v-col", { staticClass: "pl-4 pt-0", attrs: { cols: "9" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("span", { staticClass: "text-h6" }, [_vm._v(" 4.9 ")]),
                    _vm._l(4, function(item) {
                      return _c("Icon", {
                        key: item,
                        staticClass: "star",
                        attrs: {
                          data: require("@icon/star.svg"),
                          width: "16",
                          height: "16",
                          color: "#8e8f94"
                        }
                      })
                    }),
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "16",
                          height: "16",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c(
                          "g",
                          { attrs: { fill: "none", "fill-rule": "evenodd" } },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M8 1.581 9.64 6.5h4.935l-4.16 3.327 1.683 5.049L8 12.416l-4.098 2.46 1.683-5.049L1.425 6.5H6.36L8 1.581z",
                                stroke: "#8E8F94"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                fill: "#8E8F94",
                                d: "m8 13-5 3 2-6-5-4h6l2-6z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ],
                  2
                ),
                _c("div", { staticClass: "caption grey--text lighten-5" }, [
                  _vm._v(" " + _vm._s(_vm.$t("service.25Ratings")) + " ")
                ])
              ]),
              _c(
                "v-col",
                {
                  staticClass: "caption text-center pt-0",
                  attrs: { cols: "3" }
                },
                [
                  _c("span", { staticClass: "text-h6" }, [_vm._v(" 18+ ")]),
                  _c("div", [_vm._v(" " + _vm._s(_vm.$t("service.age")) + " ")])
                ]
              ),
              _c(
                "v-col",
                { staticClass: "px-4 py-0 pt-1", attrs: { cols: "12" } },
                [_c("v-divider", { attrs: { light: "" } })],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "black--text" },
            [
              _c(
                "v-col",
                { staticClass: "px-4" },
                [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v(" " + _vm._s(_vm.$t("service.introduction")) + " ")
                  ]),
                  _c("div", { staticClass: "caption" }, [
                    _vm._v(" " + _vm._s(_vm.serviceDescription) + " ")
                  ]),
                  _c("v-divider", { staticClass: "mt-6", attrs: { light: "" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "black--text" },
            [
              _c(
                "v-col",
                { staticClass: "px-4 pt-0" },
                [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("service.ratingsAndReviews")) + " "
                    )
                  ]),
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", [
                      _c("div", { staticClass: "text-center" }, [
                        _c(
                          "span",
                          { staticClass: "text-h2 font-weight-bold" },
                          [_vm._v(" 4.9 ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "grey--text darken-1 subtitle-1" },
                          [_vm._v(" " + _vm._s(_vm.$t("service.outOf5")) + " ")]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "flex-grow-1 d-flex ml-6" }, [
                      _c(
                        "div",
                        { staticClass: "text-right lh-12px" },
                        _vm._l([5, 4, 3, 2, 1], function(num) {
                          return _c(
                            "div",
                            { key: num },
                            [
                              _vm._l(num, function(item) {
                                return _c("Icon", {
                                  key: item,
                                  attrs: {
                                    data: require("@icon/star.svg"),
                                    width: "8",
                                    height: "7",
                                    color: "#8e8f94"
                                  }
                                })
                              }),
                              _c("v-divider")
                            ],
                            2
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-grow-1 " },
                        [
                          _vm._l(5, function(item) {
                            return _c(
                              "div",
                              { key: item, staticClass: "py-1 line" },
                              [
                                _c("div", {
                                  staticClass: "line-progress",
                                  class: ["line" + item]
                                })
                              ]
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-1 grey--text darken-1 subtitle-1 text-right"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("service.25Ratings")) + " "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    ])
                  ]),
                  _c("v-divider", { staticClass: "mt-6", attrs: { light: "" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "black--text" },
            [
              _c(
                "v-col",
                { staticClass: "px-4 pt-0" },
                [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v(" " + _vm._s(_vm.$t("service.newFeatures")) + " ")
                  ]),
                  _c("div", { staticClass: "grey--text darken-1 subtitle-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("service.version")) + " 10.0 ")
                  ]),
                  _c("div", { staticClass: "caption mt-1" }, [
                    _vm._v(" " + _vm._s(_vm.serviceDescription) + " ")
                  ]),
                  _c("v-divider", { staticClass: "mt-6", attrs: { light: "" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "black--text" },
            [
              _c("v-col", { staticClass: "px-4 pt-0" }, [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "text-h6 mb-3" }, [
                      _vm._v(" " + _vm._s(_vm.$t("service.information")) + " ")
                    ]),
                    _vm._l(_vm.info, function(value, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex subtitle-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "grey--text darken-1" },
                                [_vm._v(" " + _vm._s(index) + " ")]
                              ),
                              _c("v-spacer"),
                              _c("div", { staticClass: "text-right" }, [
                                _vm._v(" " + _vm._s(value) + " ")
                              ])
                            ],
                            1
                          ),
                          _c("v-divider", {
                            staticClass: "my-3",
                            attrs: { light: "" }
                          })
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }