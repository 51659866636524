var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    {
      attrs: {
        fixed: "",
        "background-color": _vm.isLiffMode ? "#B77A96" : "navBar",
        grow: "",
        height: _vm.navHeight
      }
    },
    [
      _c(
        "v-btn",
        {
          attrs: {
            color: _vm.isLiffMode ? "#B77A96" : "navBar",
            height: _vm.navHeight,
            to: _vm.isLiffMode ? "/gift" : "/bonus"
          }
        },
        [
          _vm.isLiffMode
            ? [
                _c("img", {
                  attrs: {
                    src: require("@/assets/icons/gift-icon.png"),
                    width: "37px"
                  }
                })
              ]
            : [
                _vm.$route.name === "bonus"
                  ? _c("Icon", {
                      staticClass: "primary--text nav-active",
                      attrs: {
                        data: require("@icon/bottomNav/bonus-active.svg"),
                        width: "24px",
                        height: "24px"
                      }
                    })
                  : _c("Icon", {
                      staticClass: "icon--text",
                      attrs: {
                        data: require("@icon/bottomNav/bonus.svg"),
                        width: "24px",
                        height: "24px"
                      }
                    })
              ]
        ],
        2
      ),
      _c(
        "v-btn",
        {
          staticClass: "service-btn p-relative",
          attrs: {
            color: _vm.isLiffMode ? "#B77A96" : "navBar",
            height: _vm.navHeight
          },
          on: { click: _vm.showServiceArea }
        },
        [
          _vm.isLiffMode
            ? [
                _c("img", {
                  attrs: {
                    src: require("@/assets/icons/lf-service.png"),
                    width: "37px"
                  }
                })
              ]
            : [
                _vm.$route.name === "service"
                  ? _c("Icon", {
                      staticClass: "primary--text nav-active",
                      attrs: {
                        data: require("@icon/bottomNav/service-active.svg"),
                        width: "24px",
                        height: "24px"
                      }
                    })
                  : _c("Icon", {
                      staticClass: "icon--text",
                      attrs: {
                        data: require("@icon/bottomNav/service.svg"),
                        width: "24px",
                        height: "24px"
                      }
                    })
              ],
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.serviceArea.status,
                  expression: "serviceArea.status"
                }
              ],
              staticClass: "service-items-area text-left"
            },
            [
              _c(
                "ul",
                { staticClass: "pl-0" },
                _vm._l(_vm.serviceItems, function(service, index) {
                  return _c(
                    "li",
                    {
                      key: service + index,
                      staticClass: "my-3 cursor-pointer",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.goService(service.name)
                        }
                      }
                    },
                    [
                      (service.name === "Line" && _vm.showLineService) ||
                      service.name === "24h"
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-avatar",
                                { attrs: { color: "white", size: "40" } },
                                [
                                  _c("img", {
                                    staticClass: "pa-2",
                                    attrs: {
                                      src: service.icon,
                                      alt: "line-service"
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "caption ml-2 white--text" },
                                [_vm._v(" " + _vm._s(service.text) + " ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ]
          )
        ],
        2
      ),
      _c("router-link", { staticClass: "websiteLogo", attrs: { to: "/" } }, [
        _c(
          "div",
          {
            staticClass:
              "wh-56px rounded-circle d-flex justify-center align-center",
            class: _vm.customClr
          },
          [
            _c("img", {
              staticClass: "wh-38px",
              attrs: {
                src: _vm.baseImgUrl + _vm.websiteLogo,
                alt: _vm.websiteName
              }
            })
          ]
        )
      ]),
      _c(
        "v-btn",
        {
          attrs: {
            color: _vm.isLiffMode ? "#B77A96" : "navBar",
            height: _vm.navHeight,
            to: "/record"
          }
        },
        [
          _vm.isLiffMode
            ? [
                _c("img", {
                  attrs: {
                    src: require("@/assets/icons/lf-record.png"),
                    width: "37px"
                  }
                })
              ]
            : [
                _vm.$route.name === "record"
                  ? _c("Icon", {
                      staticClass: "primary--text nav-active",
                      attrs: {
                        data: require("@icon/bottomNav/record-active.svg"),
                        width: "24px",
                        height: "24px"
                      }
                    })
                  : _c("Icon", {
                      staticClass: "icon--text",
                      attrs: {
                        data: require("@icon/bottomNav/record.svg"),
                        width: "24px",
                        height: "24px"
                      }
                    })
              ]
        ],
        2
      ),
      _c(
        "v-btn",
        {
          attrs: {
            color: _vm.isLiffMode ? "#B77A96" : "navBar",
            height: _vm.navHeight,
            to: "/user"
          }
        },
        [
          _vm.isLiffMode
            ? [
                _c("img", {
                  attrs: {
                    src: require("@/assets/icons/lf-user.png"),
                    width: "37px"
                  }
                })
              ]
            : [
                _vm.$route.name === "user"
                  ? _c("Icon", {
                      staticClass: "primary--text nav-active",
                      attrs: {
                        data: require("@icon/bottomNav/profile-active.svg"),
                        width: "24px",
                        height: "24px"
                      }
                    })
                  : _c("Icon", {
                      staticClass: "icon--text",
                      attrs: {
                        data: require("@icon/bottomNav/profile.svg"),
                        width: "24px",
                        height: "24px"
                      }
                    })
              ]
        ],
        2
      ),
      _c("v-overlay", {
        attrs: { value: _vm.serviceArea.status, opacity: ".9" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }