var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("img", {
      staticClass: "cursor-pointer",
      attrs: {
        src: require("@/assets/image/fifa-world-cup.webp"),
        alt: "fifa-world-cup",
        width: "100%"
      },
      on: { click: _vm.worldCupImgEvent }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }