var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "subNav_Box bg",
      class: [_vm.currentDevice === "ios" ? "pb-72" : "pb-56"]
    },
    [
      _c(
        "div",
        {
          staticClass:
            "subNav_LogoBox d-flex justify-space-between align-center"
        },
        [
          _c("img", {
            staticClass: "navbar__logo",
            attrs: { src: _vm.websiteLogoURL(), alt: _vm.websiteName }
          }),
          _c("Icon", {
            attrs: {
              data: require("@/assets/icons/subNav/closeIcon.svg"),
              width: "32",
              height: "32"
            },
            on: {
              click: function($event) {
                return _vm.closeSubNav(false)
              }
            }
          })
        ],
        1
      ),
      _vm.isLogin
        ? _c(
            "div",
            {
              staticClass:
                "subNav_UserInfo d-flex flex-column justify-space-between"
            },
            [
              _c("div", { staticClass: "item user-area-box d-flex" }, [
                _c("span", { staticClass: "card1" }),
                _c("div", { staticClass: "user-area" }, [
                  _c("div", [_vm._v(_vm._s(_vm.$t("subHeader.account")))]),
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v(" " + _vm._s(_vm.userAccount) + " ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "item user-balance-box d-flex" }, [
                _c("span", { staticClass: "card1" }),
                _c("div", { staticClass: "user-balance" }, [
                  _c("div", [_vm._v(_vm._s(_vm.$t("myCenter.mainWallet")))]),
                  _vm.userBalanceProgressStatus || _vm.balanceLoading
                    ? _c(
                        "div",
                        { staticStyle: { height: "36px" } },
                        [
                          _c("v-progress-circular", {
                            staticClass: "mr-4",
                            attrs: {
                              indeterminate: "",
                              width: 2,
                              size: 20,
                              color: "primary"
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between"
                        },
                        [
                          _vm.isLiffMode
                            ? [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "text-decoration-none",
                                    attrs: { to: "/user/transfer" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mr-1 title--text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.currencySymbol_) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-h5 title--text" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.userBalance) + " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            : [
                                _c(
                                  "span",
                                  { staticClass: "text-h6 title--text" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.currencySymbol_) +
                                        " " +
                                        _vm._s(_vm.userBalance) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                          !_vm.overlay && _vm.isLogin
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", "font-weight-bold": "" },
                                  on: { click: _vm.retrieveAllWalletBalance }
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "ml-4",
                                    attrs: {
                                      width: "15",
                                      src: require("@icon/user/walletItem/transfer3.png")
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm.isLogin
        ? _c(
            "div",
            { staticClass: "subNav_payment d-flex justify-space-between" },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "title--text text-center px-1 mr-3",
                        staticStyle: { background: "linear-gradient(" },
                        attrs: {
                          to: "/user/withdrawal",
                          height: "40",
                          width: "100%"
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("global.withdraw")) + " ")
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "title--text text-center px-1 thaigi-gradient",
                        style:
                          _vm.style === "darkGD"
                            ? ""
                            : _vm.customGradientBgTypeA,
                        attrs: {
                          to: "/user/deposit",
                          height: "40",
                          width: "100%"
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("global.deposit")) + " ")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "subNav_List" },
        _vm._l(_vm.itemsTree, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "d-flex align-center flex-wrap",
              class: item.children ? "allGameList" : "",
              on: {
                click: function($event) {
                  return _vm.handlerItemsTree(item.class, item.router)
                }
              }
            },
            [
              item.loginStatus
                ? _c(
                    "div",
                    {
                      staticClass:
                        "GameListTitle d-flex justify-space-between align-center"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-medium d-flex align-center"
                        },
                        [
                          _c("v-img", {
                            staticClass: "mr-2",
                            attrs: {
                              "max-width": "24",
                              src: require("@/assets/icons/subNav/" +
                                item.class +
                                ".png")
                            }
                          }),
                          _c("span", [_vm._v(_vm._s(item.game_type))])
                        ],
                        1
                      ),
                      item.children
                        ? _c(
                            "v-icon",
                            {
                              class: _vm.allGameList ? "active" : "",
                              attrs: { right: "" }
                            },
                            [_vm._v(" mdi-chevron-right ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.allGameList && item.children
                ? _c(
                    "ul",
                    { staticClass: "w-100" },
                    _vm._l(item.children[0], function(childrenItem, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "GameListTitle d-flex align-center",
                          on: {
                            click: function($event) {
                              return _vm.goVendorPage(childrenItem._id)
                            }
                          }
                        },
                        [
                          childrenItem.type == "game_type"
                            ? _c("v-img", {
                                staticClass: "mr-2",
                                attrs: {
                                  "max-width": "22",
                                  src: require("@/assets/icons/subNav/gamesIcon/" +
                                    childrenItem.class +
                                    ".png")
                                }
                              })
                            : _c("img", {
                                staticClass: "mr-2",
                                attrs: {
                                  src:
                                    _vm.baseImgUrl + _vm.navIcon(childrenItem),
                                  width: "22"
                                }
                              }),
                          _c("span", { staticClass: "font-weight-medium" }, [
                            _vm._v(" " + _vm._s(childrenItem.game_type) + " ")
                          ])
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "subNav_BtnS" },
        [
          _c(
            "router-link",
            { staticClass: "mb-2", attrs: { to: "/recommend" } },
            [
              _c(
                "v-img",
                {
                  directives: [{ name: "img", rawName: "v-img" }],
                  attrs: {
                    src: require("@/assets/icons/subNav/btn/friend.png")
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "friend txt d-flex flex-column justify-center h-100"
                    },
                    [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("myCenter.inviteFriend")))
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("myCenter.inviteSlogan")))
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "router-link",
            { attrs: { to: "/service" } },
            [
              _c(
                "v-img",
                {
                  attrs: {
                    src: require("@/assets/icons/subNav/btn/service.png")
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "service txt d-flex flex-column justify-center h-100 ml-6"
                    },
                    [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("bottomNav.service")))]),
                      _c("span", [_vm._v("24/7 online")])
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "subNav_Lan d-flex flex-wrap justify-space-between align-center"
        },
        [
          _c("div", { staticClass: "subNav_Lan_txt d-flex align-center" }, [
            _c("img", {
              staticClass: "mr-2",
              attrs: {
                src: _vm.baseImgUrl + _vm.language.active_default_Icon,
                alt: _vm.language.active_default_name,
                width: "20px",
                height: "14px"
              }
            }),
            _c("span", [_vm._v(" " + _vm._s(_vm.language.active_default_name))])
          ]),
          _c(
            "div",
            { staticClass: "subNav_Lan_icon d-flex align-center" },
            [
              _c("Icon", {
                staticClass: "secondary--text",
                attrs: {
                  data: require("@/assets/icons/subNav/lan_icon.svg"),
                  width: "15px",
                  height: "15px"
                },
                on: {
                  click: function($event) {
                    _vm.languageListVisible = !_vm.languageListVisible
                  }
                }
              })
            ],
            1
          ),
          _vm.languageListVisible
            ? _c(
                "div",
                {
                  staticClass:
                    "subNav_Lan__list d-flex flex-column align-center py-3 px-3 inputBg mt-2"
                },
                _vm._l(_vm.switchLanguages, function(lang) {
                  return _c(
                    "div",
                    {
                      key: lang.code,
                      staticClass:
                        "subNav_Lan__list__item d-flex justify-left align-center my-1 py-1 px-3",
                      class: { "active card1": lang.code === _vm.activeLang },
                      on: {
                        click: function($event) {
                          return _vm.settingClientLang(lang.code)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: _vm.baseImgUrl + lang.icon,
                          alt: lang.default_name,
                          width: "20px",
                          height: "14px"
                        }
                      }),
                      _vm._v(" " + _vm._s(lang.default_name) + " ")
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }