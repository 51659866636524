import { render, staticRenderFns } from "./UserInfoTemplate7.vue?vue&type=template&id=57742872&scoped=true&"
import script from "./UserInfoTemplate7.vue?vue&type=script&lang=js&"
export * from "./UserInfoTemplate7.vue?vue&type=script&lang=js&"
import style0 from "./UserInfoTemplate7.vue?vue&type=style&index=0&id=57742872&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57742872",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VProgressCircular,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/drone/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57742872')) {
      api.createRecord('57742872', component.options)
    } else {
      api.reload('57742872', component.options)
    }
    module.hot.accept("./UserInfoTemplate7.vue?vue&type=template&id=57742872&scoped=true&", function () {
      api.rerender('57742872', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/components/template/UserInfoTemplate7.vue"
export default component.exports