var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    { attrs: { fixed: "", grow: "", height: _vm.navHeight } },
    [
      _vm._l(_vm.navBarData, function(item, index) {
        return [
          item.path !== "/" && item.path !== "/service"
            ? _c(
                "v-btn",
                {
                  key: index,
                  attrs: {
                    height: _vm.navHeight,
                    to: item.path,
                    color: item.bgcolor
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.matchRouteName(item.path, index)
                        ? _vm.baseUrl + item.icon_active
                        : _vm.baseUrl + item.icon,
                      height: "30"
                    }
                  })
                ]
              )
            : _vm._e(),
          item.path == "/service"
            ? _c(
                "v-btn",
                {
                  key: index,
                  attrs: { height: _vm.navHeight, color: item.bgcolor },
                  on: {
                    click: function($event) {
                      return _vm.showServiceArea(item.path)
                    }
                  }
                },
                [
                  _vm.isLiffMode
                    ? [
                        _c("img", {
                          attrs: { src: _vm.baseUrl + item.icon, height: "30" }
                        })
                      ]
                    : [
                        _c("img", {
                          attrs: {
                            src: _vm.matchRouteName(item.path, index)
                              ? _vm.baseUrl + item.icon_active
                              : _vm.baseUrl + item.icon,
                            height: "30"
                          }
                        })
                      ],
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.serviceArea.status,
                          expression: "serviceArea.status"
                        }
                      ],
                      staticClass: "service-items-area text-left"
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "pl-0" },
                        _vm._l(_vm.serviceItems, function(service, idx) {
                          return _c(
                            "li",
                            {
                              key: service + idx,
                              staticClass: "my-3 cursor-pointer",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.goService(service.name)
                                }
                              }
                            },
                            [
                              (service.name === "Line" &&
                                _vm.showLineService) ||
                              service.name === "24h"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          attrs: { color: "white", size: "40" }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "pa-2",
                                            attrs: {
                                              src: service.icon,
                                              alt: "line-service"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption ml-2 white--text"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(service.text) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                2
              )
            : _vm._e(),
          item.path === "/"
            ? _c(
                "router-link",
                {
                  key: index,
                  staticClass: "websiteLogo",
                  style: {
                    "box-shadow":
                      "33px 33px 0 " +
                      item.bgcolor +
                      ", -33px 33px 0 " +
                      item.bgcolor +
                      ", 0 50px 0 " +
                      item.bgcolor
                  },
                  attrs: { to: item.path }
                },
                [
                  _c("v-avatar", { attrs: { size: "56" } }, [
                    _vm.matchRouteName(item.path, index)
                      ? _c("img", {
                          attrs: {
                            src: _vm.baseUrl + item.icon_active,
                            alt: item.title
                          }
                        })
                      : _c("img", {
                          attrs: {
                            src: _vm.baseUrl + item.icon,
                            alt: item.title
                          }
                        })
                  ])
                ],
                1
              )
            : _vm._e()
        ]
      }),
      _c("v-overlay", {
        attrs: { value: _vm.serviceArea.status, opacity: ".9" }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }