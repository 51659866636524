var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll.self",
          value: _vm.onScroll,
          expression: "onScroll",
          modifiers: { self: true }
        }
      ],
      staticClass: "pt-56 pb-10 h-100dvh over-flow-y-scroll",
      attrs: { id: "slot" }
    },
    [
      _c(_vm.layoutTemplate, { tag: "component" }),
      _c(
        "v-container",
        [
          _c(
            "SearchBarTemplate7",
            [
              _c("v-text-field", {
                staticClass: "pa-0 searchBarTemp7",
                attrs: {
                  slot: "input",
                  id: "searchInput",
                  autofocus: "",
                  "hide-details": "",
                  height: "36",
                  placeholder: _vm.$t("game.searchGame"),
                  rounded: "",
                  autocomplete: "off",
                  dense: ""
                },
                on: { focus: _vm.setSearchMode, input: _vm.setSearchMode },
                slot: "input",
                model: {
                  value: _vm.form.filter,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "filter",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.filter"
                }
              }),
              _vm.form.filter
                ? _c(
                    "v-btn",
                    {
                      attrs: { slot: "clearInputTest", icon: "" },
                      on: { click: _vm.clearFilterKeyword },
                      slot: "clearInputTest"
                    },
                    [
                      _c("v-icon", { attrs: { size: "16" } }, [
                        _vm._v(" mdi-close-circle ")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.games.length === 0
            ? [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "mt-10", attrs: { cols: "12" } },
                      [
                        _c("Nodata", {
                          staticClass: "mt-10",
                          attrs: {
                            text: _vm.$t("game.noGames", {
                              type: _vm.tabItem[_vm.tab]
                            })
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [
                _c("GameList", {
                  attrs: {
                    games: _vm.games,
                    "scroll-bottom": _vm.scrollBottom
                  },
                  on: { "favorite-game-toggle": _vm.favoriteGameToggle }
                })
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }