var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "header p-relative" },
      [
        _c("v-img", {
          staticClass: "header__bg",
          attrs: { src: _vm.baseImgUrl + _vm.serviceData.bgBanner }
        }),
        _c("div", { staticClass: "header__description p-absolute d-flex" }, [
          _c("div", { staticClass: "w-70" }, [
            _c("div", { staticClass: "text-h5 icon--text font-weight-bold" }, [
              _vm._v(
                " " + _vm._s(_vm.websiteName + _vm.serviceData.title) + " "
              )
            ]),
            _c("div", [_vm._v(" " + _vm._s(_vm.serviceData.description) + " ")])
          ]),
          _c("div")
        ])
      ],
      1
    ),
    _c("div", { staticClass: "px-6 p-relative" }, [
      _c(
        "div",
        { staticClass: "primary rounded text-center pt-8 pb-6" },
        [
          _c("Icon", {
            attrs: {
              data: require("@icon/onliveService.svg"),
              height: "50",
              width: "80"
            }
          }),
          _c("div", { staticClass: "text-h6 white--text mt-1" }, [
            _vm._v(" " + _vm._s(_vm.$t("global.onlineCs")) + " ")
          ]),
          _c("div", { staticClass: "subtitle-2 btnText--text mt-2 mb-5" }, [
            _vm._v(" " + _vm._s(_vm.$t("service.24hourService")) + " ")
          ]),
          _c(
            "v-btn",
            {
              staticClass: "navBar",
              attrs: { height: "32" },
              on: { click: _vm.toServicePage }
            },
            [
              _c("span", { staticClass: "white--text caption" }, [
                _vm._v(" " + _vm._s(_vm.$t("service.contactService")) + " ")
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }