var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "pa-0 ma-0",
      attrs: { fixed: "", flat: "", color: _vm.color || _vm.bgClr }
    },
    [
      _vm.hasToken
        ? _c(
            "v-btn",
            {
              staticClass: "pa-0",
              attrs: { icon: "" },
              on: { click: _vm.goBack }
            },
            [
              _c("Icon", {
                staticClass: "title--text",
                attrs: {
                  data: require("@icon/toolbar/toolbar_goback_arrow.svg"),
                  width: "16px",
                  height: "16px"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("v-toolbar-title", { staticClass: "title--text font-weight-medium" }, [
        _vm._v(" " + _vm._s(_vm.title) + " ")
      ]),
      _c("v-spacer"),
      _vm._t("btn")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }