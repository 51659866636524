var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "current_champion text-center champion-bg mt-4 mx-7 pa-1 rounded"
        },
        [
          _c(
            "div",
            { staticClass: "white py-3" },
            [
              _c("h1", { staticClass: "primary--text font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.$t("game.championOfThisIssue")) + " ")
              ]),
              _c("img", {
                staticClass: "mt-4",
                attrs: {
                  src: require("@/assets/image/champion.png"),
                  alt: "current_champion",
                  width: "80px"
                }
              }),
              _vm._l(_vm.activeData.champion, function(user, index) {
                return _c("div", { key: index, staticClass: "my-3" }, [
                  _c(
                    "div",
                    { staticClass: "current_champion-user black--text" },
                    [_vm._v(" " + _vm._s(user.account) + " ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "current_champion-user black--text mt-1" },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("game.get")) + " "),
                      _c(
                        "strong",
                        {
                          class:
                            _vm.activeTab === 0 ? "dragon-text" : "tiger-text"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(user.times + _vm.$t("myCenter.times")) +
                              " "
                          )
                        ]
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("game.dayChampion")) + " ")
                    ]
                  )
                ])
              })
            ],
            2
          )
        ]
      ),
      _vm._l(_vm.activeData.weeklyRank, function(weekData, index) {
        return _c(
          "div",
          { key: index, staticClass: "champions mt-5 mx-15" },
          [
            _c(
              "div",
              {
                staticClass:
                  "text-center black subtitle-1 font-weight-bold py-1 white--text"
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("game.championWeek", { week: weekData.week })
                    ) +
                    " "
                )
              ]
            ),
            _c("div", { staticClass: "champion-bg rounded pa-1 mb-4" }, [
              _c(
                "div",
                { staticClass: "user white" },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0 black--text" },
                    [
                      !weekData.champion
                        ? [
                            _c(
                              "v-col",
                              {
                                staticClass: "content-bg text-center",
                                attrs: { cols: "12" }
                              },
                              [_vm._v(" ... ")]
                            )
                          ]
                        : [
                            _c(
                              "v-col",
                              {
                                staticClass: "img-bg pa-0",
                                attrs: { cols: "6" }
                              },
                              [
                                _c("img", {
                                  staticClass: "h-100 w-100 object-fit-cover",
                                  attrs: {
                                    src:
                                      _vm.baseImgUrl + weekData.champion.image
                                  }
                                })
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "pa-0 text-right caption content-bg",
                                attrs: { cols: "6" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "px-3 py-1 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(weekData.champion.date) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "border-bt px-3 py-1" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(weekData.champion.account) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "px-3 py-1 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(weekData.champion.amount) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _vm._l(weekData.dailyRank, function(user, userIndex) {
              return _c(
                "div",
                {
                  key: userIndex,
                  staticClass: "champion-bg rounded pa-1 mb-4"
                },
                [
                  _c(
                    "div",
                    { staticClass: "user white" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0 black--text" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "img-bg pa-0",
                              attrs: { cols: "6" }
                            },
                            [
                              _c("img", {
                                staticClass: "h-100 w-100 object-fit-cover",
                                attrs: { src: _vm.baseImgUrl + user.image }
                              })
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 text-right caption",
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "px-3 py-1 font-weight-bold" },
                                [_vm._v(" " + _vm._s(user.date) + " ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "border-bt px-3 py-1" },
                                [_vm._v(" " + _vm._s(user.account) + " ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "px-3 py-1 font-weight-bold" },
                                [_vm._v(" " + _vm._s(user.amount) + " ")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            })
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }