var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "h-100dvh over-flow-y-scroll",
      class: [_vm.currentDevice === "ios" ? "pb-72" : "pb-56"],
      style: { "padding-top": _vm.fixLayoutHeight + "px" },
      attrs: { id: "home" }
    },
    [
      _c("button", { ref: "hiddenButton", staticClass: "hidden-btn" }),
      _c(
        "Dialog",
        {
          attrs: { dialog: _vm.dialog.status, width: 280, height: 170 },
          on: { "update-dialog": _vm.setSafariDialogStatus }
        },
        [
          _c(
            "p",
            { staticClass: "px-6 mb-0", attrs: { slot: "text" }, slot: "text" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("flashMessage.browserSetting", {
                      browser: _vm.device
                    })
                  ) +
                  " "
              )
            ]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.dialog.status = !_vm.dialog.status
                }
              },
              slot: "btn"
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "overlay-opacity": ".8", "max-width": 280 },
          model: {
            value: _vm.newsDialog,
            callback: function($$v) {
              _vm.newsDialog = $$v
            },
            expression: "newsDialog"
          }
        },
        [
          _vm.layoutNotTemplate3OrTemplate4
            ? _c(
                "div",
                { staticClass: "text-right mb-3" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "close-dialog-icon mr-1",
                      attrs: {
                        icon: "",
                        outlined: "",
                        dark: "",
                        "max-height": "24",
                        "max-width": "24"
                      },
                      on: {
                        click: function($event) {
                          _vm.newsDialog = false
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { size: "18", color: "white" } }, [
                        _vm._v(" mdi-close ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card",
            { staticClass: "bg rounded-lg", attrs: { height: "356" } },
            [
              _c(
                "v-container",
                { staticClass: "ma-0 h-100 p-relative", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex py-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.activeNews > 0,
                                  expression: "activeNews > 0"
                                }
                              ],
                              staticClass: "prev"
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "card2",
                                  on: { click: _vm.prevNews }
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "primary--text mr-1",
                                    attrs: {
                                      data: require("@icon/double-arrows-left.svg"),
                                      width: "20",
                                      height: "20"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "div",
                            { staticClass: "next" },
                            [
                              _c(
                                "v-btn",
                                {
                                  class: {
                                    card2: _vm.layoutNotTemplate3OrTemplate4
                                  },
                                  attrs: {
                                    text: !_vm.layoutNotTemplate3OrTemplate4
                                  },
                                  on: { click: _vm.nextNews }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-1 caption",
                                      class: _vm.layoutNotTemplate3OrTemplate4
                                        ? "primary--text"
                                        : "text--text"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.nextItem")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("Icon", {
                                    staticClass: "mr-1",
                                    class: _vm.layoutNotTemplate3OrTemplate4
                                      ? "primary--text"
                                      : "text--text",
                                    attrs: {
                                      data: require("@icon/double-arrows-right.svg"),
                                      width: "20",
                                      height: "20"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0 px-6", attrs: { cols: "12" } },
                        [
                          _vm.layoutNotTemplate3OrTemplate4
                            ? _c("div", { staticClass: "mt-3 text-center" })
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "subtitle-1 text-center font-weight-bold",
                                  class: [
                                    _vm.layout === "template3" &&
                                    _vm.theme === "light"
                                      ? "title--text"
                                      : "secondary--text"
                                  ]
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("global.notice")) + " "
                                  )
                                ]
                              ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "news-title subtitle-1 font-weight-medium mt-5 text-overflow",
                              class: [
                                _vm.layout === "template3" &&
                                _vm.theme === "light"
                                  ? "primary--text"
                                  : "title--text"
                              ]
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.news[_vm.activeNews].title) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "news-contnet caption mt-4 text-content-overflow fix-img-size fix-img-height",
                              class: {
                                "text--text": !_vm.layoutNotTemplate3OrTemplate4
                              }
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.news[_vm.activeNews].content
                                  )
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-absolute d-flex align-center not-remember w-100 px-4"
                    },
                    [
                      _c(
                        "v-checkbox",
                        {
                          staticClass: "primary--text",
                          attrs: { dense: "" },
                          model: {
                            value: _vm.notRememberMe,
                            callback: function($$v) {
                              _vm.notRememberMe = $$v
                            },
                            expression: "notRememberMe"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "caption",
                              class: _vm.layoutNotTemplate3OrTemplate4
                                ? "primary--text"
                                : "text--text",
                              attrs: { slot: "label" },
                              slot: "label"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("global.DontShowAgainToday")) +
                                  " "
                              )
                            ]
                          )
                        ]
                      ),
                      !_vm.layoutNotTemplate3OrTemplate4
                        ? [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass: "caption",
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.newsDialog = false
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("global.gotIt")) + " "
                                )
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "cant-open-app-dialog",
          attrs: { "overlay-opacity": ".4", "max-width": 280 },
          model: {
            value: _vm.cantOpenAppDialog.status,
            callback: function($$v) {
              _vm.$set(_vm.cantOpenAppDialog, "status", $$v)
            },
            expression: "cantOpenAppDialog.status"
          }
        },
        [
          _c(
            "div",
            { staticClass: "text-right mb-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-dialog-icon mr-1",
                  attrs: {
                    icon: "",
                    outlined: "",
                    dark: "",
                    "max-height": "24",
                    "max-width": "24"
                  },
                  on: {
                    click: function($event) {
                      _vm.cantOpenAppDialog.status = false
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { size: "18", color: "white" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-snackbar",
                {
                  staticClass: "snackbar",
                  attrs: { centered: "", timeout: _vm.timeout },
                  model: {
                    value: _vm.snackbar,
                    callback: function($$v) {
                      _vm.snackbar = $$v
                    },
                    expression: "snackbar"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("service.longPressThePictureToSave")) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "bg rounded-lg p-relative" },
            [
              _c(
                "v-container",
                { staticClass: "ma-0 h-100 p-relative", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 p-relative",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.baseImgUrl +
                                _vm.csProfile.backup_domains_image
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex actions p-absolute w-100 px-6"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-grow-1 mr-2" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "card2",
                                      attrs: { block: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.snackbar = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.save")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "flex-grow-1" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btnText--text",
                                      attrs: { block: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.copyText("copyText")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.copy")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("input", {
                                    attrs: { id: "copyText", type: "text" },
                                    domProps: {
                                      value: _vm.csProfile.backup_domains_text
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { ref: "fixedAreaRef", staticClass: "fixed-area bg" },
        [
          _c("DownloadApp", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.downloadAppDsp,
                expression: "downloadAppDsp"
              }
            ],
            ref: "downloadApp",
            on: { "close-download-app": _vm.closeDownloadApp }
          }),
          _vm.layoutIsTemplate1OrTemplate5 ? _c("Navbar") : _vm._e(),
          _vm.layoutNotTemplate7 ? _c("UserInfo") : _vm._e(),
          _vm.layoutNotTemplate7 ? _c("Marquee") : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fix-component-wrapper bg" },
        _vm._l(_vm.fixLayoutArea, function(item, index) {
          return _c(item.block, {
            key: item.block + "-" + index,
            ref: "apiFixComponent",
            refInFor: true,
            tag: "component",
            attrs: { data: item }
          })
        }),
        1
      ),
      _vm.isParentRendered
        ? _c(
            "div",
            {
              staticClass: "component-wrapper",
              style: { "padding-top": _vm.apiFixComponentsHeight + "px" }
            },
            _vm._l(_vm.layoutArea, function(item, index) {
              return _c(item.block, {
                key: item.block + "-" + index,
                tag: "component",
                attrs: {
                  data: item,
                  "api-fix-components-height": _vm.apiFixComponentsHeight,
                  "fix-layout-height": _vm.fixLayoutHeight,
                  "is-parent-rendered": _vm.isParentRendered,
                  "layout-area-length": _vm.layoutAreaLength
                }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }