var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    {
      attrs: {
        fixed: "",
        "background-color": "navBar",
        grow: "",
        height: _vm.navHeight
      }
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "service-btn p-relative",
          attrs: { to: "/", color: "navBar", height: _vm.navHeight }
        },
        [
          _vm.$route.name === "home"
            ? _c("Icon", {
                staticClass: "primary--text nav-active",
                attrs: {
                  data: require("@icon/bottomNav/home.svg"),
                  width: "22px",
                  height: "22px"
                }
              })
            : _c("Icon", {
                staticClass: "hint--text",
                attrs: {
                  data: require("@icon/bottomNav/home.svg"),
                  width: "22px",
                  height: "22px"
                }
              })
        ],
        1
      ),
      _c(
        "v-btn",
        { attrs: { color: "navBar", height: _vm.navHeight, to: "/bonus" } },
        [
          _vm.$route.name === "bonus"
            ? _c("Icon", {
                staticClass: "primary--text nav-active",
                attrs: {
                  data: require("@icon/bottomNav/gif.svg"),
                  width: "22px",
                  height: "22px"
                }
              })
            : _c("Icon", {
                staticClass: "hint--text",
                attrs: {
                  data: require("@icon/bottomNav/gif.svg"),
                  width: "22px",
                  height: "22px"
                }
              })
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "service-btn p-relative",
          attrs: { color: "navBar", height: _vm.navHeight },
          on: { click: _vm.showServiceArea }
        },
        [
          _vm.$route.name === "service"
            ? _c("Icon", {
                staticClass: "primary--text nav-active",
                attrs: {
                  data: require("@icon/bottomNav/service-template3.svg"),
                  width: "31px",
                  height: "31px"
                }
              })
            : _c("Icon", {
                staticClass: "hint--text",
                attrs: {
                  data: require("@icon/bottomNav/service-template3.svg"),
                  width: "31px",
                  height: "31px"
                }
              }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.serviceArea.status,
                  expression: "serviceArea.status"
                }
              ],
              staticClass: "service-items-area text-left"
            },
            [
              _c(
                "ul",
                { staticClass: "pl-0" },
                _vm._l(_vm.serviceItems, function(service, index) {
                  return _c(
                    "li",
                    {
                      key: service + index,
                      staticClass: "my-3 cursor-pointer",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.goService(service.name)
                        }
                      }
                    },
                    [
                      (service.name === "Line" && _vm.showLineService) ||
                      service.name === "24h"
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-avatar",
                                { attrs: { color: "white", size: "40" } },
                                [
                                  _c("img", {
                                    staticClass: "pa-2",
                                    attrs: {
                                      src: service.icon,
                                      alt: "line-service"
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "caption ml-2 white--text" },
                                [_vm._v(" " + _vm._s(service.text) + " ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-btn",
        { attrs: { color: "navBar", height: _vm.navHeight, to: "/record" } },
        [
          _vm.$route.name === "record"
            ? _c("Icon", {
                staticClass: "primary--text nav-active",
                attrs: {
                  data: require("@icon/bottomNav/cord-template3.svg"),
                  width: "22px",
                  height: "22px"
                }
              })
            : _c("Icon", {
                staticClass: "hint--text",
                attrs: {
                  data: require("@icon/bottomNav/cord-template3.svg"),
                  width: "22px",
                  height: "22px"
                }
              })
        ],
        1
      ),
      _c(
        "v-btn",
        { attrs: { color: "navBar", height: _vm.navHeight, to: "/user" } },
        [
          _vm.$route.name === "user"
            ? _c("Icon", {
                staticClass: "primary--text nav-active",
                attrs: {
                  data: require("@icon/bottomNav/user-template3.svg"),
                  width: "22px",
                  height: "22px"
                }
              })
            : _c("Icon", {
                staticClass: "hint--text",
                attrs: {
                  data: require("@icon/bottomNav/user-template3.svg"),
                  width: "22px",
                  height: "22px"
                }
              })
        ],
        1
      ),
      _c("v-overlay", {
        attrs: { value: _vm.serviceArea.status, opacity: ".9" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }