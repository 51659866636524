var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vh-100 d-flex flex-column justify-center align-center px-12"
    },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("Icon", {
            attrs: {
              data: require("@icon/alert/error.svg"),
              width: "60",
              height: "60",
              color: "red"
            }
          }),
          _c("h1", [_vm._v("500")]),
          _c("span", [_vm._v("Internal Server Error")])
        ],
        1
      ),
      _c("div", { staticClass: "text-center caption my-5" }, [
        _vm._v(" " + _vm._s(_vm.errorText) + " "),
        _c(
          "span",
          {
            staticClass: "primary--text cursor-pointer",
            on: { click: _vm.toServicePage }
          },
          [_vm._v(_vm._s(_vm.$t("flashMessage.contactCustomerService")))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }