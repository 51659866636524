import { render, staticRenderFns } from "./BottomNavMode5.vue?vue&type=template&id=94efc0fe&scoped=true&"
import script from "./BottomNavMode5.vue?vue&type=script&lang=js&"
export * from "./BottomNavMode5.vue?vue&type=script&lang=js&"
import style0 from "./BottomNavMode5.vue?vue&type=style&index=0&id=94efc0fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94efc0fe",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VAvatar,VBottomNavigation,VBtn,VOverlay})


/* hot reload */
if (module.hot) {
  var api = require("/drone/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('94efc0fe')) {
      api.createRecord('94efc0fe', component.options)
    } else {
      api.reload('94efc0fe', component.options)
    }
    module.hot.accept("./BottomNavMode5.vue?vue&type=template&id=94efc0fe&scoped=true&", function () {
      api.rerender('94efc0fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/template/BottomNavMode5.vue"
export default component.exports