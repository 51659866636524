import { render, staticRenderFns } from "./BottomNavTemplate4.vue?vue&type=template&id=662b5009&scoped=true&"
import script from "./BottomNavTemplate4.vue?vue&type=script&lang=js&"
export * from "./BottomNavTemplate4.vue?vue&type=script&lang=js&"
import style0 from "./BottomNavTemplate4.vue?vue&type=style&index=0&id=662b5009&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662b5009",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VAvatar,VBottomNavigation,VBtn,VOverlay})


/* hot reload */
if (module.hot) {
  var api = require("/drone/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('662b5009')) {
      api.createRecord('662b5009', component.options)
    } else {
      api.reload('662b5009', component.options)
    }
    module.hot.accept("./BottomNavTemplate4.vue?vue&type=template&id=662b5009&scoped=true&", function () {
      api.rerender('662b5009', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/template/BottomNavTemplate4.vue"
export default component.exports