var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "navbar d-flex align-center",
      class: [
        _vm.layout === "template7"
          ? "justify-space-between pl-5 pr-5"
          : "justify-center"
      ]
    },
    [
      _vm.layout != "template7"
        ? _c(
            "div",
            { staticClass: "navbar__language" },
            [
              _vm.layout === "template6"
                ? _c("Icon", {
                    attrs: {
                      data: require("@/assets/icons/subNav/icon_hamburger.svg"),
                      width: "25px",
                      height: "30px"
                    },
                    on: {
                      click: function($event) {
                        _vm.drawer = !_vm.drawer
                      }
                    }
                  })
                : _c(
                    "div",
                    { staticClass: "global d-flex align-center" },
                    [
                      _c("Icon", {
                        attrs: {
                          data: _vm.iconGlobal,
                          width: "24px",
                          height: "24px"
                        },
                        on: {
                          click: function($event) {
                            _vm.languageListVisible = !_vm.languageListVisible
                          }
                        }
                      })
                    ],
                    1
                  ),
              _vm.languageListVisible
                ? _c(
                    "div",
                    {
                      staticClass:
                        "navbar__language__list d-flex flex-column align-center py-2 bg"
                    },
                    _vm._l(_vm.switchLanguages, function(lang) {
                      return _c(
                        "div",
                        {
                          key: lang.code,
                          staticClass:
                            "navbar__language__list__item d-flex justify-center align-center my-1 py-1",
                          class: { active: lang.code === _vm.activeLang },
                          on: {
                            click: function($event) {
                              return _vm.settingClientLang(lang.code)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.baseImgUrl + lang.icon,
                              alt: lang.default_name,
                              width: "20px",
                              height: "20px"
                            }
                          })
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("img", {
        staticClass: "navbar__logo",
        attrs: { src: _vm.websiteLogoURL(), alt: _vm.websiteName }
      }),
      _vm.layout === "template7"
        ? _c(
            "router-link",
            {
              staticClass: "mail-link",
              style: { "background-color": _vm.secondaryClr },
              attrs: { to: "/user/news" }
            },
            [
              _c("Icon", {
                attrs: {
                  data: require("@/assets/icons/home/mail.svg"),
                  width: "15"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-navigation-drawer",
        {
          attrs: { absolute: "", temporary: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _vm.layout === "template6" && _vm.drawer
            ? _c("SubNav", {
                staticClass: "sub-nav-box",
                on: { "close-sub-nav": _vm.closeSubNav }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }