var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    {
      staticClass: "bottom-nav",
      attrs: { fixed: "", grow: "", height: _vm.navHeight },
      model: {
        value: _vm.navIndex,
        callback: function($$v) {
          _vm.navIndex = $$v
        },
        expression: "navIndex"
      }
    },
    [
      _vm._l(_vm.navBarData, function(item, index) {
        return [
          item.path !== "/service"
            ? _c(
                "v-btn",
                {
                  key: index,
                  attrs: {
                    color: _vm.matchRouteName(item.path, index)
                      ? item.bgcolor2
                      : item.bgcolor,
                    height: _vm.navHeight,
                    to: item.path
                  }
                },
                [
                  _vm.layout === "template7"
                    ? _c(
                        "span",
                        {
                          staticClass: "nav-text grey-text",
                          class: [
                            _vm.matchRouteName(item.path, index)
                              ? "primary--text"
                              : ""
                          ]
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    : _vm._e(),
                  _c("img", {
                    attrs: {
                      src: _vm.matchRouteName(item.path, index)
                        ? _vm.baseUrl + item.icon_active
                        : _vm.baseUrl + item.icon,
                      height: "56"
                    }
                  })
                ]
              )
            : _c(
                "v-btn",
                {
                  key: index,
                  attrs: {
                    color: _vm.matchRouteName(item.path, index)
                      ? item.bgcolor2
                      : item.bgcolor,
                    height: _vm.navHeight
                  },
                  on: {
                    click: function($event) {
                      return _vm.showServiceArea(item.path)
                    }
                  }
                },
                [
                  _vm.isLiffMode
                    ? [
                        _c("img", {
                          attrs: { src: _vm.baseUrl + item.icon, height: "56" }
                        })
                      ]
                    : [
                        _vm.layout === "template7"
                          ? _c(
                              "span",
                              {
                                staticClass: "nav-text grey-text",
                                class: [
                                  _vm.matchRouteName(item.path, index)
                                    ? "primary--text"
                                    : ""
                                ]
                              },
                              [_vm._v(_vm._s(item.title))]
                            )
                          : _vm._e(),
                        _c("img", {
                          attrs: {
                            src: _vm.matchRouteName(item.path, index)
                              ? _vm.baseUrl + item.icon_active
                              : _vm.baseUrl + item.icon,
                            height: "56"
                          }
                        })
                      ],
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.serviceArea.status,
                          expression: "serviceArea.status"
                        }
                      ],
                      staticClass: "service-items-area text-left"
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "pl-0" },
                        _vm._l(_vm.serviceItems, function(service, idx) {
                          return _c(
                            "li",
                            {
                              key: service + idx,
                              staticClass: "my-3 cursor-pointer",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.goService(service.name)
                                }
                              }
                            },
                            [
                              (service.name === "Line" &&
                                _vm.showLineService) ||
                              service.name === "24h"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          attrs: { color: "white", size: "40" }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "pa-2",
                                            attrs: {
                                              src: service.icon,
                                              alt: "line-service"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption ml-2 white--text"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(service.text) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                2
              )
        ]
      }),
      _c("v-overlay", {
        attrs: { value: _vm.serviceArea.status, opacity: ".9" }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }