var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-56" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "pt-2 px-3 pb-2" },
            _vm._l(_vm.recommendItem, function(item, index) {
              return _c(
                "v-col",
                {
                  key: index,
                  staticClass: "pa-1 text-center",
                  attrs: { cols: "4" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card1 py-2 rounded d-flex align-center flex-column justify-center caption"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "recommend__title title--text font-weight-medium"
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "recommend__num primary--text text-h6",
                          class: [{ "secondary--text": item.item === "total" }]
                        },
                        [_vm._v(" " + _vm._s(_vm.recommend[item.item]) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "recommend__description hint--text" },
                        [_vm._v(" " + _vm._s(item.description) + " ")]
                      )
                    ]
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-1" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "divider" })],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { id: "recommend-info" } },
            [
              _c("v-col", { attrs: { cols: "2" } }),
              _c(
                "v-col",
                {
                  staticClass: "text-center title--text",
                  attrs: { cols: "8" }
                },
                [
                  _c("div", { staticClass: "description__title text-h6" }, [
                    _vm._v(" " + _vm._s(_vm.$t("recommend.title")) + " ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "description__subtitle subtitle-1 mb-6" },
                    [_vm._v(" " + _vm._s(_vm.$t("recommend.subtitle")) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "description__description caption hint--text"
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("recommend.description")) + " "
                      )
                    ]
                  )
                ]
              ),
              _c("v-col", { attrs: { cols: "2" } }),
              _c(
                "v-col",
                { attrs: { cols: "12 text-center" } },
                [
                  _c("QrcodeVue", {
                    staticClass: "pt-4 px-4 pb-2 white qrcode rounded",
                    attrs: {
                      value: _vm.recommendResgisterUrl,
                      size: "92",
                      level: "L",
                      foreground: "black"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-3 pt-3" },
            [
              _c("v-col", { attrs: { cols: "2" } }),
              _c(
                "v-col",
                {
                  staticClass: "py-0 caption title--text px-1",
                  attrs: { cols: "8" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.recommendCode")) + " ")]
              ),
              _c("v-col", { attrs: { cols: "2" } }),
              _c("v-col", { attrs: { cols: "2" } }),
              _c(
                "v-col",
                { staticClass: "py-0 px-1", attrs: { cols: "8" } },
                [
                  _c(
                    "v-text-field",
                    {
                      staticClass: "mt-2 red--text",
                      attrs: {
                        autofocus: "",
                        readonly: "",
                        "min-height": "36",
                        dense: "",
                        outlined: "",
                        required: "",
                        value: _vm.recommendCode
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          attrs: { slot: "append" },
                          slot: "append"
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", height: "20", width: "20" },
                              on: {
                                click: function($event) {
                                  return _vm.copyNumber("recommendCode")
                                }
                              }
                            },
                            [
                              _c("Icon", {
                                staticClass: "title--text",
                                attrs: {
                                  data: require("@icon/copy.svg"),
                                  width: "16",
                                  height: "16"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "2" } }),
              _c(
                "v-col",
                {
                  staticClass: "py-0 px-1 text-center pb-5",
                  attrs: { cols: "12" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "btn",
                        color: "success",
                        text: "",
                        height: "64"
                      },
                      on: {
                        click: function($event) {
                          return _vm.copyNumber("recommendResgisterUrl")
                        }
                      },
                      slot: "btn"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column align-center justify-center"
                        },
                        [
                          _c("Icon", {
                            staticClass: "icon--text",
                            attrs: {
                              data: require("@icon/copylink.svg"),
                              width: "24px",
                              height: "24px"
                            }
                          }),
                          _c(
                            "span",
                            { staticClass: "caption title--text mt-2" },
                            [_vm._v(_vm._s(_vm.$t("global.copyRecommendUrl")))]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "text-decoration-none",
                      attrs: {
                        href: _vm.qrCodeCanvasImgUrl,
                        download: "recommend-qrcode"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            slot: "btn",
                            color: "success",
                            text: "",
                            height: "64"
                          },
                          slot: "btn"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column align-center justify-center"
                            },
                            [
                              _c("Icon", {
                                staticClass: "icon--text",
                                attrs: {
                                  data: require("@icon/download.svg"),
                                  width: "24px",
                                  height: "24px"
                                }
                              }),
                              _c(
                                "span",
                                { staticClass: "caption title--text mt-2" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("global.downloadQRcode"))
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("input", {
                attrs: { id: "recommendCode", type: "text" },
                domProps: { value: _vm.recommendCode }
              }),
              _c("input", {
                attrs: { id: "recommendResgisterUrl", type: "text" },
                domProps: { value: _vm.recommendResgisterUrl }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "d-none" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }