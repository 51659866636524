var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll.self",
          value: _vm.onScroll,
          expression: "onScroll",
          modifiers: { self: true }
        }
      ],
      staticClass: "pt-56 pb-10 h-100dvh over-flow-y-scroll",
      attrs: { id: "slot" }
    },
    [
      _vm.mode === "general"
        ? [
            _c(
              _vm.layoutTemplate,
              { tag: "component" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { slot: "btn", icon: "" },
                    on: { click: _vm.setSearchMode },
                    slot: "btn"
                  },
                  [
                    _c("Icon", {
                      staticClass: "white--text",
                      attrs: {
                        data: require("@icon/search.svg"),
                        width: "24",
                        height: "24"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  { staticClass: "p-sticky top-0 z-index-1" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "py-0 px-0", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-tabs",
                          {
                            attrs: {
                              grow: "",
                              "background-color": "bg",
                              color: "primary"
                            },
                            model: {
                              value: _vm.tab,
                              callback: function($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab"
                            }
                          },
                          _vm._l(_vm.tabItem, function(item) {
                            return _c(
                              "v-tab",
                              { key: item, staticClass: "px-0" },
                              [
                                _c("span", { staticClass: "subtitle-1" }, [
                                  _vm._v(_vm._s(item))
                                ])
                              ]
                            )
                          }),
                          1
                        ),
                        _c("v-divider", { staticClass: "divider" })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.games.length === 0
                  ? [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "mt-10", attrs: { cols: "12" } },
                            [
                              _c("Nodata", {
                                staticClass: "mt-10",
                                attrs: {
                                  text: _vm.$t("game.noGames", {
                                    type: _vm.tabItem[_vm.tab]
                                  })
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : [
                      _c("GameList", {
                        attrs: {
                          games: _vm.games,
                          "scroll-bottom": _vm.scrollBottom
                        },
                        on: { "favorite-game-toggle": _vm.favoriteGameToggle }
                      })
                    ]
              ],
              2
            )
          ]
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode === "search",
              expression: "mode === 'search'"
            }
          ]
        },
        [
          _c(
            "SearchBar",
            [
              _c(
                "v-btn",
                {
                  attrs: { slot: "searchCancelBtn", icon: "" },
                  on: { click: _vm.setGeneralMode },
                  slot: "searchCancelBtn"
                },
                [
                  _vm.layout === "template3"
                    ? [
                        _c("Icon", {
                          staticClass: "icon--text",
                          attrs: {
                            data: require("@icon/toolbar/toolbar_goback_arrow.svg"),
                            width: "16px",
                            height: "16px"
                          }
                        })
                      ]
                    : [
                        _c("Icon", {
                          staticClass: "icon--text",
                          attrs: {
                            data: require("@icon/toolbar/arrow_left.svg"),
                            width: "16px",
                            height: "16px"
                          }
                        })
                      ]
                ],
                2
              ),
              _c("v-text-field", {
                staticClass: "pa-0",
                attrs: {
                  slot: "input",
                  id: "searchInput",
                  autofocus: "",
                  "hide-details": "",
                  height: "36",
                  placeholder: _vm.$t("game.searchGame"),
                  rounded: "",
                  autocomplete: "off",
                  dense: ""
                },
                slot: "input",
                model: {
                  value: _vm.form.filter,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "filter",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.filter"
                }
              }),
              _vm.form.filter
                ? _c(
                    "v-btn",
                    {
                      attrs: { slot: "clearInputTest", icon: "" },
                      on: { click: _vm.clearFilterKeyword },
                      slot: "clearInputTest"
                    },
                    [
                      _c("v-icon", { attrs: { size: "16" } }, [
                        _vm._v(" mdi-close-circle ")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "px-1" },
                [
                  _c("v-col", { staticClass: "caption comment--text" }, [
                    !_vm.searchResolve
                      ? _c(
                          "div",
                          { staticClass: "search__descrition-unsearch" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("game.PleaseEnterKeywordToSearch")
                                ) +
                                " "
                            )
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "search__descrition-search" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("game.resultsFound")) + " "
                            ),
                            _c(
                              "span",
                              { staticClass: "primary--text subtitle-2" },
                              [_vm._v(" " + _vm._s(_vm.games.length) + " ")]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("game.aboutKeyword", {
                                    keyword: _vm.form.filter
                                  })
                                ) +
                                " "
                            )
                          ]
                        )
                  ])
                ],
                1
              ),
              _vm.games.length === 0 && _vm.searchResolve
                ? [_c("NodataBig", { staticClass: "mt-11 pt-11" })]
                : [
                    _c("GameList", {
                      attrs: {
                        games: _vm.games,
                        "scroll-bottom": _vm.scrollBottom
                      },
                      on: { "favorite-game-toggle": _vm.favoriteGameToggle }
                    })
                  ]
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }