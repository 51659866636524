var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "px-4 d-flex align-center justify-start marquee",
      class: _vm.layout === "template7" ? "bg" : "card2",
      attrs: { id: "marquree" }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c("Icon", {
            staticClass: "secondary--text mr-2 marquree__icon",
            attrs: {
              data: require("@icon/alert.svg"),
              width: "16",
              height: "16"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "marquree__text comment--text subtitle-2" }, [
        _c(
          "div",
          {
            staticClass: "text",
            style: { width: _vm.marqueeContentLength * 12 + "px" }
          },
          _vm._l(_vm.marqueeContent, function(marquee) {
            return _c(
              "span",
              {
                key: marquee._id,
                staticClass: "cursor-pointer",
                on: {
                  click: function($event) {
                    return _vm.marqueeDialog(marquee._id)
                  }
                }
              },
              [_vm._v(" " + _vm._s(marquee.content) + " ")]
            )
          }),
          0
        )
      ]),
      _vm.layout === "template3"
        ? _c(
            "DialogsTemplate3",
            {
              attrs: { width: 280, dialog: _vm.dialog.status },
              on: { "update-dialog": _vm.setDialogStatue }
            },
            [
              _c(
                "div",
                {
                  staticClass: "white--text subtitle-1 w-100 text-center py-2",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(" " + _vm._s(_vm.dialog.title) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "px-6 subtitle-2 text--text mt-4",
                  attrs: { slot: "text" },
                  slot: "text"
                },
                [_vm._v(" " + _vm._s(_vm.dialog.text) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "w-100 text-center",
                  attrs: { slot: "btn" },
                  slot: "btn"
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.dialog.status = false
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
                  )
                ],
                1
              )
            ]
          )
        : _c(
            "Dialogs",
            {
              attrs: { width: 280, dialog: _vm.dialog.status },
              on: { "update-dialog": _vm.setDialogStatue }
            },
            [
              _c(
                "div",
                {
                  staticClass: "title--text subtitle-1",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(" " + _vm._s(_vm.dialog.title) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "px-6 subtitle-2 text--text",
                  attrs: { slot: "text" },
                  slot: "text"
                },
                [_vm._v(" " + _vm._s(_vm.dialog.text) + " ")]
              ),
              _c(
                "div",
                { attrs: { slot: "btn" }, slot: "btn" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.dialog.status = false
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
                  )
                ],
                1
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }