var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-56", attrs: { id: "agent" } },
    [
      _c(
        "Toolbar",
        { staticClass: "toolbar" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                slot: "btn",
                icon: "",
                color: "success",
                to: "/service"
              },
              slot: "btn"
            },
            [
              _c("Icon", {
                staticClass: "icon--text",
                attrs: {
                  data: require("@icon/bottomNav/service.svg"),
                  width: "24px",
                  height: "24px"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "h-100" },
        [
          _c(
            "v-row",
            { staticClass: "px-1" },
            [
              _c(
                "v-col",
                {
                  staticClass: "header text-center pb-1",
                  attrs: { cols: "12" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header__img text-center d-flex justify-center"
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm.websiteLogo
                            ? _c("v-img", {
                                staticClass: "cursor-pointer",
                                attrs: {
                                  id: "goAdminImg",
                                  width: "72",
                                  contain: "",
                                  height: "72",
                                  src: _vm.baseImgUrl + _vm.websiteLogo
                                }
                              })
                            : _vm._e(),
                          !_vm.websiteLogo
                            ? _c("h1", { staticClass: "title--text" }, [
                                _vm._v(" " + _vm._s(_vm.websiteName) + " ")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "header__subtitle title--text my-2" },
                    [_vm._v(" " + _vm._s(_vm.$t("agent.page1Title")) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "header__title text-h6 font-weight-medium vipCard1 my-1"
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("agent.page1Subtitle")) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "header__description comment--text subtitle-2 my-1"
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("agent.page1Desc")) + " ")]
                  )
                ]
              ),
              _vm._l(_vm.joinUsItem, function(item, index) {
                return _c(
                  "v-col",
                  {
                    key: index,
                    staticClass: "text-center py-5",
                    attrs: { cols: "6" }
                  },
                  [
                    _c("Join0" + (index + 1), {
                      tag: "component",
                      attrs: { color: _vm.primaryColor }
                    }),
                    _c("div", { staticClass: "title--text caption" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "agent_slider2.section" + (index + 1) + "_title"
                            )
                          ) +
                          " "
                      )
                    ])
                  ],
                  1
                )
              }),
              _vm._l(_vm.contacts, function(item) {
                return _c("v-col", { key: item._id, attrs: { cols: "12" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "contact inputBg d-flex justify-start px-6 align-center rounded",
                      style: { border: "solid 1px " + _vm.primaryColor }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "contact__img mr-5" },
                        [
                          _c("v-img", {
                            attrs: {
                              width: "32",
                              height: "32",
                              contain: "",
                              src: _vm.imgCheck(item.type)
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "contact__text caption" }, [
                        _c(
                          "div",
                          {
                            staticClass: "contact__text-name mb-1 title--text"
                          },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "contact__text--info link--text",
                            attrs: { id: item.type }
                          },
                          [_vm._v(" " + _vm._s(item.info) + " ")]
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-1",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.copyText(item.type)
                            }
                          }
                        },
                        [
                          _c("Icon", {
                            staticClass: "subtitle--text",
                            attrs: {
                              data: require("@icon/copy.svg"),
                              width: "24",
                              height: "24"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              }),
              _c(
                "v-col",
                { staticClass: "pt-5 pb-6", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        height: "44",
                        block: "",
                        color: "primary",
                        to: "/service"
                      }
                    },
                    [
                      _c("span", { staticClass: "btnText--text subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.$t("myCenter.joinUs")) + " ")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }