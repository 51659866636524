var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "180" },
      model: {
        value: _vm.status,
        callback: function($$v) {
          _vm.status = $$v
        },
        expression: "status"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass:
            "d-flex align-center justify-center flex-column popupbg containerStyle",
          attrs: { height: "96px" }
        },
        [
          _vm.iconUrl
            ? _c("Icon", {
                class: _vm.color,
                attrs: { data: _vm.iconUrl, width: "32px", height: "32px" }
              })
            : _vm._e(),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.text,
                  expression: "text"
                }
              ],
              staticClass: "mt-2 px-2 subtitle-2 text-center textw--text"
            },
            [_vm._v(_vm._s(_vm.text) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }