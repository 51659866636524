var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "ma-0 px-0 py-1",
      attrs: { fixed: "", flat: "", color: _vm.color || "bg" }
    },
    [
      _c(
        "div",
        {
          staticClass: "rounded-l-xl rounded-r-xl d-flex align-center w-100",
          class: _vm.bgClr
        },
        [
          _c(
            "div",
            { staticClass: "cancelSearch" },
            [_vm._t("searchCancelBtn")],
            2
          ),
          _c("div", { staticClass: "searchInput" }, [_vm._t("input")], 2),
          _c("div", { staticClass: "clear" }, [_vm._t("clearInputTest")], 2)
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }