var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100dvh over-flow-y-scroll" },
    [
      _c("Toolbar"),
      _c(
        "div",
        { staticClass: "pt-56 bg pb-5" },
        [
          _c(
            "v-row",
            { staticClass: "ma-0 mt-1" },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-h5 font-weight-bold text-center",
                  class: [
                    _vm.activeTab === 0
                      ? "tab__dragon-active primary--text"
                      : "navBar tab__dragon"
                  ],
                  on: {
                    click: function($event) {
                      return _vm.setTab(0)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("game.dragon")) + " ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-h5 font-weight-bold text-center",
                  class: [
                    _vm.activeTab === 1
                      ? "tab__tiger-active primary--text"
                      : "navBar tab__tiger"
                  ],
                  on: {
                    click: function($event) {
                      return _vm.setTab(1)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("game.tiger")) + " ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "ma-0 navBar mt-5 px-5 py-2",
              class: _vm.activeTab === 0 ? "dragon__record" : "tiger__record"
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center pa-1 caption",
                  attrs: { cols: _vm.itemsWidth }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0 subtitle-2",
                      class: [_vm.activeRecord === 0 ? "white--text" : ""],
                      attrs: { block: "", color: _vm.activeRecordTabStyle(0) },
                      on: {
                        click: function($event) {
                          return _vm.setRecordTab(0)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("game.lastResults")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center pa-1 caption",
                  attrs: { cols: _vm.itemsWidth }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0 subtitle-2",
                      class: [_vm.activeRecord === 1 ? "white--text" : ""],
                      attrs: { block: "", color: _vm.activeRecordTabStyle(1) },
                      on: {
                        click: function($event) {
                          return _vm.setRecordTab(1)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("game.currentSituation")) + " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center pa-1 caption",
                  attrs: { cols: _vm.itemsWidth }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0 subtitle-2",
                      attrs: { block: "", color: _vm.activeRecordTabStyle(2) },
                      on: { click: _vm.showRules }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("game.rules")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center pa-1",
                  attrs: { cols: _vm.itemsWidth === 4 ? 12 : _vm.itemsWidth }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "subtitle-2",
                      attrs: { block: "" },
                      on: { click: _vm.goVendorPage }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("game.play")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(_vm.components[_vm.activeRecord], {
            tag: "component",
            attrs: { "active-tab": _vm.activeTab, "res-data": _vm.resData }
          }),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "600px" },
                  model: {
                    value: _vm.dialog,
                    callback: function($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "d-relative" },
                    [
                      _c(
                        "div",
                        { staticClass: "text-right px-2 p-sticky" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "close-btn",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialog = false
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { dense: "" } }, [
                                _vm._v(" mdi-close ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "h1",
                            { staticClass: "text-center primary--text" },
                            [_vm._v(" " + _vm._s(_vm.$t("game.dtRule")) + " ")]
                          ),
                          _c("v-img", {
                            attrs: {
                              src:
                                "https://giocoplus.gf-gaming.com/uploads/images/line.png"
                            }
                          }),
                          _c("p", { staticClass: "mt-4 mb-2 caption" }, [
                            _vm._v(" " + _vm._s(_vm.rules.rule_explain) + " ")
                          ]),
                          _c("v-img", {
                            attrs: {
                              src: _vm.baseImgUrl + _vm.rules.rule_image
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }