import { render, staticRenderFns } from "./UserInfoTemplate6.vue?vue&type=template&id=576610f1&scoped=true&"
import script from "./UserInfoTemplate6.vue?vue&type=script&lang=js&"
export * from "./UserInfoTemplate6.vue?vue&type=script&lang=js&"
import style0 from "./UserInfoTemplate6.vue?vue&type=style&index=0&id=576610f1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576610f1",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VOverlay,VProgressCircular,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/drone/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('576610f1')) {
      api.createRecord('576610f1', component.options)
    } else {
      api.reload('576610f1', component.options)
    }
    module.hot.accept("./UserInfoTemplate6.vue?vue&type=template&id=576610f1&scoped=true&", function () {
      api.rerender('576610f1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/components/template/UserInfoTemplate6.vue"
export default component.exports