var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "pa-0 ma-0",
      attrs: { fixed: "", flat: "", color: "#C01200" }
    },
    [
      _c(
        "v-btn",
        { staticClass: "pa-0", attrs: { icon: "" }, on: { click: _vm.goBack } },
        [
          _vm.layout === "template3" && _vm.theme === "light"
            ? [
                _c("Icon", {
                  staticClass: "white--text",
                  attrs: {
                    data: require("@icon/toolbar/toolbar_goback_arrow.svg"),
                    width: "16px",
                    height: "16px"
                  }
                })
              ]
            : [
                _c("Icon", {
                  staticClass: "icon--text",
                  attrs: {
                    data: require("@icon/toolbar/toolbar_goback_arrow.svg"),
                    width: "16px",
                    height: "16px"
                  }
                })
              ]
        ],
        2
      ),
      _vm.layout === "template4" && _vm.theme === "light"
        ? [
            _c(
              "v-toolbar-title",
              { staticClass: "white--text font-weight-medium" },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            )
          ]
        : [
            _c(
              "v-toolbar-title",
              { staticClass: "title--text font-weight-medium" },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            )
          ],
      _c("v-spacer"),
      _vm._t("btn")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }